var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "ul",
    { staticClass: "artist-list" },
    [
      _vm._l(_vm.data, function(row, key) {
        return [
          _c(
            "div",
            { key: key },
            [
              row.memberYN === "y"
                ? _c("a-hot-item", { attrs: { row: row } })
                : _vm.profilePage && _vm.profilePage.profile
                ? _c("a-profile-item", {
                    attrs: { row: row, state: _vm.profilePage.state }
                  })
                : _c("a-item", { attrs: { row: row } }),
              !_vm.noDivisionLine
                ? [_c("div", { staticClass: "ui-border-line" })]
                : _vm._e()
            ],
            2
          )
        ]
      })
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }