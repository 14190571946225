<template>
  <li class="artist-item" :class="{'wait': state == 'wait'}">
    <PuSkeleton :class="{'skeleton': imageLoading}" :loading="imageLoading" width="17rem" height="17rem">
      <div
      class="artist-img ui-bg-img"
      :style="{
          backgroundImage: `url(${$lib.cdnUrl(row.teamImgUrl || row.imgUrl1)})`
      }"
      >
        <div v-if="state == 'temp'" class="progress">
          {{row.percent}}% 작성완료
        </div>
        <div v-else-if="state == 'wait'" class="progress">승인 대기</div>
        <div class="artist-badge">
            <div class="badge-read" v-if="isRead">읽음</div>
            <!-- <div class="artistCheck" v-if="row.checkDate > 0">신원인증</div> -->
            <div class="artistHot" v-if="row.popularDate > 0 && state == 'done'">추천</div>
            <div class="artistCheck" v-if="!!row.eventMemo && state == 'done'">이벤트</div>
        </div>
      </div>
    </PuSkeleton>
    <div class="artist-info">
      <template v-if="isEarlybirdEventArtist">
        <div class="artistEvent">
          {{Math.round(row.salePer || 0)}}%할인 - {{earlybirdEventTimer}} 남음
        </div>
      </template>

      <div class="userNickName">{{row.teamName}}</div>
      <button
        type="button"
        class="more-btn"
        @click.stop="() => toggleDropDown()"
      >
        <icon src="img/common/more.png" width="2.6rem" height="2.6rem" :cover="false" />
      </button>
      <div class="more-opt-wrap" :class="{'open': this.dropDownOpen == true}">
        <ul class="more-opt-list">
          <li class="more-opt" v-if="row.categoryUid == 1 ">
            <button type="button" @click="() => gotoProfile(row)">프로필 수정</button> <!-- 프로필 등록 - 기본 정보 화면으로 이동 -->
          </li>
          <li class="more-opt" v-else>
            <button type="button" @click="() => preview(row)">미리보기</button> <!-- 행사패키지/행사용품의 경우 미리보기로 이동 -->
          </li>
          <li class="more-opt">
            <button 
              v-if="state=='done'" 
              type="button" 
              @click.stop="() => {
                toggleDropDown()
                $refs.hideModal.show()
              }"
            >
              비공개하기
            </button>
          </li>
          <li class="more-opt">
            <button 
              v-if="state=='temp' && row.percent != '100'" 
              type="button" 
              @click.stop="() => {
                toggleDropDown()
                $refs.deleteModal.show()
              }"
            >
              삭제하기
            </button>
          </li>
          <li class="more-opt">
            <button type="button" @click.stop="() => toggleDropDown()">취소</button>
          </li>
        </ul>
      </div>
      <!-- <div class="genre-location">
        {{ $lib.t_genre(row.categoryType != 'supplies' ? row.genre : row.categoryName) }}<span>&middot;</span>{{$lib.getLocationText(row.location)}}
      </div> -->
      <ul class="tag" v-if="row.hashTag">
        <template v-for="(tag, tagKey) in row.hashTag.split(/,\s?/)">
          <li
            :key="tagKey"
            v-if="tag"
           >
            {{tag}}
          </li>
        </template>
      </ul>

      <div v-if="state=='done'">
        <AEvaluation :favCount="row.favCount" :pointCount="row.pointCount" :reviewCount="row.reviewCount"></AEvaluation>
      </div>
      <template v-if="isEarlybirdEventArtist">
        <div class="artist-info-price">
          <span
            :style="{
              textDecoration: 'line-through',
              fontSize: '18px',
              color: '#b6b6b6',
              marginRight: '6px',
            }"
          >
            {{$lib.addComma(row.proPrice)}}원
          </span>
          <span>{{$lib.addComma($lib.floor(Number(row.proPrice) - $lib.floor(Number(row.proPrice) * Number(row.salePer) / 100), 2))}}원</span>
        </div>
      </template>
      <template v-else-if="isAppliedArtist">
        <div class="artist-info-price">
          {{$lib.addComma(row.proPrice)}}원
        </div>
      </template>
      <template v-else-if="isDiscountEventCustomArtist && state=='done'">
        <div class="artist-info-price">
          <span class="color-lavender">{{Math.round(row.salePer || 0)}}%</span> {{$lib.addComma($lib.floor(Number(row.cusPriceStart) - $lib.floor(Number(row.cusPriceStart) * Number(row.salePer) / 100), 2))}}원
        </div>
      </template>
      <!-- v1.0.0 변동가 case -->
      <template v-else-if="isFluctuating">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원 ~ {{$lib.addComma(row.cusPriceEnd)}}원
        </div>
      </template>
      <!-- v1.0.0 변동가 case -->
      <template v-else-if="isFluctuatingStart">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원 ~ 
        </div>
      </template>
      <!-- // v1.0.0 변동가 case -->
      <template v-else-if="isCustomArtist && row.cusPriceStart > 0">
        <div class="artist-info-price">
          {{$lib.addComma(row.cusPriceStart)}}원
        </div>
      </template>
      <div v-if="state == 'done'" class="userUpdateDate">수정한지 <span class="period">{{updateDate}}일</span>이 지났어요!</div>
    </div>
    <modal ref="hideModal"
    confirm="비공개"
    :confirmCb="hideProfile"
    >
      <template slot="body">
        <div class="text-center" style="white-space: pre-line; padding: 2rem 1rem; font-size: 1.8rem;">
          다시 공개하려면 승인 요청이 필요해요.<br>그래도 비공개하시겠어요?
        </div>
      </template>
    </modal>
    <modal ref="deleteModal"
    confirm="삭제"
    :confirmCb="deleteProfile"
    >
      <template slot="body">
        <div class="text-center" style="white-space: pre-line; padding: 2rem 1rem; font-size: 1.8rem">
          삭제된 정보는 복구가 불가합니다.<br>정말 삭제하시겠습니까?
        </div>
      </template>
    </modal>
  </li>
</template>

<script>
import StarRating from 'vue-star-rating'
import AEvaluation from './AEvaluation.vue'
import Icon from '@/components/common/Icon.vue'
import Modal from '../common/Modal.vue'

import {
  isAppliedArtist,
  isEarlybirdEventArtist,
  calcEarlybirdEventTimer,
  isCustomArtist,
  isDiscountEventCustomArtist,
  isFluctuating,
} from '@/lib/artist.js'

export default {
  components: {
    StarRating,
    AEvaluation,
    Icon,
    Modal
  },
  props: {
    row: {
      type: Object,
      required: true,
    },
    noSearchArtistByTag: {
      type: Boolean,
      default: false,
    },
    state: {
      type: String,
      default: 'done'
    } // 등록완료 'done' / 심사대기 'wait' / 임시저장 'temp' 구분을 위한 props
  },
  data() {
    return {
      offerData: {},
      earlybirdEventTimer: '24:00:00',
      imageLoading:false,
      dropDownOpen: false, // 프로필 드롭다운 메뉴 열림/닫힘을 위한 변수
    }
  },
  computed: {
    userData: {
      get() {
        return this.$store.state.user.userData
      }
    },
    offerUid() {
      return this.$route.params?.offerUid || this.row?.offerUid
    },
    isRead() {
      if (this.userData.category === 'client') {
        return localStorage.getItem(`read:estimate:${this.row.offerUid}:${this.row.artistidx}`) > 0
      }

      return false
    },
    isAppliedArtist() {
      return isAppliedArtist(this.row)
    },
    isEarlybirdEventArtist() {
      return this.isAppliedArtist && isEarlybirdEventArtist(this.row, this.offerData)
    },
    isCustomArtist() {
      return isCustomArtist(this.row, this.userData)
    },
    isDiscountEventCustomArtist() {
      return isDiscountEventCustomArtist(this.row)
    },
    isFluctuating() {
      return isFluctuating(this.row) && this.row.cusPriceEnd > 0;
    },
    isFluctuatingStart() {
      return isFluctuating(this.row);
    },
    updateDate() {
      const date1 = new Date();
      let date2 = new Date(this.row.createdDate * 1000);
      if(this.row.userUpdateDate != null){
        date2 = new Date(this.row.userUpdateDate);
        // 낮은 버전에서 iso 타임 변환이 안됨.. 
        if(date2 instanceof Date && isNaN(date2)) {
          date2 = new Date(this.row.userUpdateDate.replace(' ','T'));
        }
      }
      const diffDate = date1.getTime() - date2.getTime();
      return Math.floor(diffDate / (1000 * 60 * 60 * 24));
    }
  },
  created() {
    if (this.isAppliedArtist) {
      this.getOffer()
    }
    this.loadBackgroundImages();
  },
  methods: {
    gotoProfile(row) {
      if (this.$route.name === 'home') {
        this.$router.push(`/artist/my-profile`)
      } else if (!this.$route.params.offerUid) {
        this.$router.push({
          path: `/artist/my-profile`,
          query: {
            artistidx: row.artistidx,
          },
        })
      } else {
        sessionStorage.setItem('offerData', JSON.stringify(row))
        this.$router.push(`/client/my-offer/${row.offerUid}/${row.artistidx}`)
      }
      this.toggleDropDown();
    },
    preview(row) {
      this.$store.commit('setArtistData', row)
      this.$router.push('/artist/my-profile/preview')
    },
    hideProfile(modal) {
      const req = {
          method: 'put',
          url: `/artist/profile/disable/${this.row.artistidx}`,
      }
      return this.$http(req)
        .then(({ data }) => {
          // 프로필 다시 불러오기 
          this.$store.dispatch('getProfileList')

          return
          })
        .catch((e) => {
          log.error(e)
          return
        })
        .finally(() => {
          this.$refs.hideModal.hide();
        })
    },
    deleteProfile(modal) {
      const req = {
          method: 'delete',
          url: `/artist/profile/${this.row.artistidx}`,
      }
      return this.$http(req)
        .then(({ data }) => {
          // 프로필 다시 불러오기 
          this.$store.dispatch('getProfileList')

          return
          })
        .catch((e) => {
          log.error(e)
          return
        })
        .finally(() => {
          this.$refs.deleteModal.hide();
        })
    },
    startTimer() {
      calcEarlybirdEventTimer(this.row, this.offerData, (time) => {
        this.earlybirdEventTimer = time
      })
    },
    searchArtistByTag(tag) {
      //
      // if (!tag) {
      //   return
      // }

      // if (this.userData.category !== 'client') {
      //   return
      // }

      // if (this.noSearchArtistByTag) {
      //   return
      // }

      // this.$router.push({
      //   path: '/artist/list',
      //   query: {
      //     hashTag: tag,
      //   },
      // })
    },

    loadBackgroundImages() {
      this.imageLoading = true;

      const img = new Image();

      img.src = this.$lib.cdnUrl(this.row.teamImgUrl || this.row.imgUrl1);

      img.onload = () => {
        this.imageLoading = false;
      };

      img.onerror = (e) => {
        this.imageLoading = false;
      }; 
    },
    toggleDropDown() {
      let container = document.querySelector('.container');
      if(this.dropDownOpen) container.style.overflow = 'auto';
      else  container.style.overflow = 'hidden';
      this.dropDownOpen = !this.dropDownOpen;
    } // 프로필 드롭다운 토글 이벤트
  },
}
</script>
<style lang="scss" scoped>
  @import '@/assets/css/constant.scss';

  .artist-item {
    display: flex;
    overflow: hidden;
    position: relative;
    margin: 2.8rem auto;

    .artist-badge {
      overflow: hidden;

      > div {
        display: inline-block;
        width: auto;
        padding: 0 0.6rem;
        margin-right: 0.6rem;
        margin-bottom: 1.6rem;
        line-height: 2.5rem;
        height: 2.2rem;
        border-radius: 0.5rem;
        color: #fff;
        font-size: 1.4rem;
        text-align: center;
        &.artistCheck {
          background-color: #30c39e;
        }
        &.artistHot {
          background-color: #a059b6;
        }
        &.badge-read {
          background-color: #ffcb39;
        }
        &.badge-event-outline {
          color: $color-deepLavender;
          background-color: transparent;
          border: 1px solid $color-deepLavender;
        }
      }
    }

    .skeleton {
      margin-right: 2.8rem;
    }

    .artist-img {
      overflow: hidden;
      position: relative;
      float: left;
      margin-right: 2.8rem;
      min-width: 17rem;
      width: 17rem;
      height: 21.4rem;
      border-radius: 1rem;
      background-color: #d8d8d8;

      .progress {
        justify-content: center;
        height: auto;
        padding: 0.8rem 0;
        border-radius: 0;
        background-color: rgba(0,0,0,0.6);
        color: white;
        font-size: 1.4rem;
        font-weight: 600;
      }

      .artist-badge {
        position: absolute;
        left: 8px;
        bottom: -10px;
      }
    }

    .artist-info {
      display: flex;
      flex-direction: column;
      width: 100%;
      // max-width: 28.2rem;
      padding: 0.9rem 0;
      overflow: hidden;
      margin-right: 2.6rem;

      .userNickName {
        margin-bottom: 1rem;
        font-size: 2rem;
        line-height: 1.2;
        font-weight: 500;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
      }

      .genre-location {
        margin-bottom: 0.9rem;
        font-size: 1.8rem;
        line-height: 1.8rem;
        color: #848484;

        span {
          color: #d8d8d8;
          padding: 0 0.5rem;
        }
      }

      .tag {
        display: flex;
        flex-wrap: wrap;
        gap: 6px 0;
        overflow: hidden;
        margin-bottom: 1.4rem;
        max-height: 59.18px;

        li {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          padding: .3rem 1rem;
          color: #666;
          font-size: 1.3rem;
          line-height: 1.86rem;
          margin-right: .5rem;
          background-color: #f5f5f5;
          border: 1px solid #ededed;
          border-radius: 2px;
        }
      }

      .evaluation {
        margin: 0;
      }

      .review {
        font-size: 1.6rem;
        line-height: 2rem;
        color: #5a5a5a;

        .rating {
          float: left;
          overflow: hidden;
          height: 18px;
          margin-right: 1rem;
        }
      }

      .artistEvent {
        margin: 1rem 0 0.4rem;
        font-size: 1.6rem;
        font-weight: 500;
        color: $color-pink;
      }

      .artist-info-price {
        margin-top: 0.8rem;
        color: #000;
        font-size: 2.2rem;
        font-weight: 700;
      }

      .userUpdateDate {
        margin-top: auto;
        font-size: 1.6rem;
        color: #555;
        text-align: right;
        .period {
          color: $color-deepLavender;
        }
      }
    }
    .more-btn {
      position: absolute;
      top: 0.9rem;
      right: -0.8rem;
      width: 2.6rem;
      height: 2.6rem;
    } 
    .more-opt-wrap {
      display: none;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      gap: 0.4rem 0;
      position: fixed;
      top: 0;
      left: 0;
      z-index: 2;
      width: 100%;
      height: 100%;
      padding: 0 2rem;
      background-color: rgba(0, 0, 0, 0.2);
      .btn-close {
        width: 2.4rem;
        height: 2.4rem;
        margin: 0 0 0 auto;
        background: url('../../../public/img/artist/close@3x.png') no-repeat center / cover;
      }
      .more-opt-list {
        width: 100%;
        background-color: #fff;
        button {
          width: 100%;
          padding: 1.7rem 0;
          font-size: 1.6rem;
          line-height: 2.2rem;
          color: #333;
        }
        li + li {
          button {
            border-top: 0.1rem solid #eee;
          }
        }
      }
      &.open {
        display: flex;
      }
    }

    &.wait {
      .artist-info {
        .userNickName {
          color: #999;
        }
        &-price {
          color: #999;
        }
      }
    }
  }
</style>
