<template>
  <ul class="artist-list">
    <template v-for="(row, key) in data">
      <div :key="key">
        <a-hot-item v-if="row.memberYN === 'y'" :row="row" />
        <a-profile-item v-else-if=" profilePage && profilePage.profile" :row="row" :state="profilePage.state"/>
        <a-item v-else :row="row"/>
        <template v-if="!noDivisionLine">
          <div class="ui-border-line" />
        </template>
      </div>
    </template>
  </ul>
</template>

<script>
import AItem from '@/components/artist/AItem'
import AHotItem from '@/components/artist/AHotItem'
import AProfileItem from '@/components/artist/AprofileItem'

export default {
  components: {
    AItem,
    AHotItem,
    AProfileItem,
  },
  props: {
    data: {
      type: Array,
      default: () => [],
    },
    noDivisionLine : {
      type: Boolean,
      default: false
    },
    // profilePage : {
    //   type: Boolean,
    //   default: false
    // }
    profilePage : {
      profile: {
        type: Boolean,
        default: false
      },
      state: {
        type: 'done' | 'wait' | 'temp',
        default: 'done'
      }
    }
  },
  methods: {},
}
</script>

<style lang="scss" scoped>
// .artist-list {
//   > div {
//     &:nth-child(n+6) {
//       display: none;
//     }
//   }
// }
</style>